import { XMarkIcon } from '@heroicons/react/20/solid';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslations } from 'next-intl';

type Props = {
  message: string;
  type: 'success' | 'error';
  onClose: () => void;
};

export default function Notification({ message, type, onClose }: Props) {
  // Callbacks.
  const handleClose = () => {
    onClose();
  };

  // Translations.
  const t = useTranslations();

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div className="flex w-full flex-col items-center sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

        <div className="pointer-events-auto w-full rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-[5]">
          <div className="p-4">
            <div className="flex items-start">
              <div className="">
                {type === 'success' ? (
                  <CheckCircleIcon
                    className="size-6 text-green-400"
                    aria-hidden="true"
                  />
                ) : (
                  <ExclamationCircleIcon
                    className="size-6 text-red-400"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="ml-3 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">
                  {type === 'success'
                    ? t('general.label-success')
                    : t('general.label-error')}
                </p>
                <p className="mt-1 text-sm text-gray-500">{message}</p>
              </div>
              <div className="ml-4 flex">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
