import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type Notification = {
  id: number;
  message: string;
  type: 'success' | 'error';
};

type State = {
  notifications: Notification[];
};

type Actions = {
  addNotification: (message: string, type: 'success' | 'error') => number;
  removeNotification: (id: number) => void;
};

const useNotifications = create<State & Actions>()(
  devtools(
    immer((set) => ({
      notifications: [],
      addNotification: (message, type) => {
        const id = Date.now();
        set((state) => {
          state.notifications.push({ id, message, type });
        });

        // Automatically remove the notification after 5 seconds
        setTimeout(() => {
          set((state) => {
            state.notifications = state.notifications.filter(
              (n) => n.id !== id,
            );
          });
        }, 5000);

        return id;
      },
      removeNotification: (id) =>
        set((state) => {
          state.notifications = state.notifications.filter((n) => n.id !== id);
        }),
    })),
  ),
);

export default useNotifications;
