'use client';

import Notification from '@/components/Notification';
import useNotifications from '@/stores/notifications';
import type { FC } from 'react';

const NotificationsGrid: FC = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <div className="fixed right-0 top-0 z-50 m-4 min-w-min space-y-4">
      {notifications.map(({ id, ...notification }) => (
        <Notification
          key={id}
          {...notification}
          onClose={() => removeNotification(id)}
        />
      ))}
    </div>
  );
};

export default NotificationsGrid;
